import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

class Thumbs extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          thumbs: []
        };
    }

    componentDidMount() {
        const API = 'http://admin.nytff.basedigital.io/wp-json/';
        const API_QUERY = "wp/v2/thumbs";

        fetch(API + API_QUERY)
        .then(response => response.json())
        .then(data => {
            this.setState({ thumbs: data[0].acf.thumbs })
        });        
    }

    render() {
        const { thumbs } = this.state;
        
        return (
          <div id="wrapper">
            {thumbs.map( (item, index) =>
              <div class="thumb" key={item.objectID}>
                <img src={item.image} />
              </div>
            )}
          </div>
        );
    }
}

class POC extends React.Component {

    render() {
        return (
            <div>
                <center><h1>NYTFF POC</h1></center>
                <Thumbs />
            </div>
        )
    }
}
  
ReactDOM.render(
    <POC/>,
    document.getElementById('root')
);
  